<template>
  <div>
    <div v-if="props.data && props.data.results.length > 0">
      <div class="overflow-scroll md:overflow-auto">
        <table class="w-full m-auto table-auto">
          <thead>
            <tr>
              <th class="text-center" v-if="props.checkboxes">Select</th>
              <th
                v-for="label in props.labels"
                :key="label"
                class="py-3 text-center"
              >
                {{ label }}
              </th>
            </tr>
          </thead>
          <tbody class="h-4/6">
            <tr
              v-for="result in props.data.results"
              :key="result.id"
              class="bg-white"
            >
              <td v-if="props.checkboxes" class="text-center">
                <input
                  type="checkbox"
                  v-model="selectedItems"
                  :value="result.email"
                />
              </td>

              <td class="text-center">
                {{ result.email }}
              </td>
              <td class="text-center">
                {{ result.first_name }} {{ result.last_name }}
              </td>
              <td class="text-center">
                {{ formatDate(result.create_at) }}
              </td>
              <td class="text-center">{{ result.profiles }}</td>
              <td
                @click="press(result.id)"
                v-if="props.edit"
                class="text-center"
              >
                <i class="fa-solid fa-pen-to-square"></i>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="bg-white text-center p-3 w-full">
        <span
          @click="previousPage"
          class="mr-3 font-bold cursor-pointer"
          v-if="actualPage > 1"
          >&lt;</span
        >
        <p class="inline">{{ actualPage }} of {{ totalPages }} pages</p>
        <span
          @click="nextPage"
          class="ml-3 font-bold cursor-pointer"
          v-if="props.data && props.data.next"
          >&gt;</span
        >
      </div>
    </div>
    <div v-else>
      <div class="overflow-scroll md:overflow-auto">
        <table class="w-full m-auto">
          <thead>
            <tr>
              <th
                v-for="label in props.labels"
                :key="label"
                class="py-3 text-center"
              >
                {{ label }}
              </th>
            </tr>
          </thead>
          <tbody></tbody>
        </table>
      </div>
      <div class="bg-white w-full m-auto p-16">
        <img src="@/assets/empty-table.svg" alt="" class="block m-auto" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, defineExpose, computed } from "vue";
import { useRouter } from "vue-router";

const router = useRouter();

const selectedItems = ref([]);
const actualPage = ref(1);
const totalPages = computed(() =>
  props?.data?.count ? Math.ceil(props.data.count / 10) : 1
);

const emit = defineEmits(["changePage"]);

const props = defineProps({
  data: {
    type: Object,
    default: null,
  },
  labels: {
    type: Array,
    default: null,
  },
  edit: {
    type: Boolean,
    default: false,
  },
  checkboxes: {
    type: Boolean,
    default: false,
  },
});

function press(id) {
  router.push({ name: "Edit user profiles", params: { id } });
}

function formatDate(date) {
  const dateAdded = new Date(date);
  return `${
    dateAdded.getMonth() + 1
  }/${dateAdded.getDate()}/${dateAdded.getFullYear()}`;
}

function nextPage() {
  if (props?.data.next) {
    actualPage.value++;
    emit("changePage", actualPage.value);
  }
}

function previousPage() {
  if (props?.data.previous && actualPage.value > 1) {
    actualPage.value--;
    emit("changePage", actualPage.value);
  }
}

defineExpose({
  selectedItems,
});
</script>

<style>
table {
  border: none;
}

th {
  color: #1d2e48;
  background: #ecf0f5;
}

tr {
  border-top: 1px solid #ecf0f5;
  border-bottom: 2px solid #ecf0f5;
}

th,
td {
  border: none;
}

tr td {
  padding: 20px;
}

tr:hover td {
  background: #4e5066;
  color: #ffffff;
}

tr:first-child {
  border-top: none;
}

tr:last-child {
  border-bottom: none;
}
</style>
