<template>
  <div class="pb-6">
    <div class="overflow-x-auto">
      <table class="fixed-width-table min-w-full">
        <thead>
          <tr>
            <th v-if="props.checkboxes" class="py-3 px-3 text-center">
              <input
                type="checkbox"
                v-model="selectAll"
                @change="toggleSelectAll"
              />
            </th>
            <th
              @click="$emit('toggle-order', label.value, actualPage)"
              class="cursor-pointer py-3 px-3 text-center"
              v-for="label in labels"
              :key="label"
            >
              {{ label.text ? label.text : label }}
              <span
                v-if="props.isOrderedColumns && isOrderedBy(label.value, 'asc')"
                >↑</span
              >
              <span
                v-if="
                  props.isOrderedColumns && isOrderedBy(label.value, 'desc')
                "
                >↓</span
              >
            </th>
          </tr>
        </thead>

        <tbody class="h-4/6">
          <slot
            name="table-content"
            :selected-items="selectedItems"
            :toggle-selection="toggleSelection"
          ></slot>
        </tbody>
      </table>
    </div>
    <div class="bg-white text-center p-3 w-full" v-if="bottomArrows">
      <span
        @click="previousPage"
        class="mr-3 font-bold cursor-pointer"
        v-if="actualPage > 1"
        >&lt;</span
      >
      <p class="inline">{{ actualPage }} of {{ totalPages }} pages</p>
      <span
        @click="nextPage"
        class="ml-3 font-bold cursor-pointer"
        v-if="props.data && props.data.next"
        >&gt;</span
      >
    </div>
  </div>
</template>

<script setup>
// eslint-disable-next-line no-unused-vars
import { ref, defineProps, defineEmits, computed, watch } from "vue";

const props = defineProps({
  data: {
    type: Object || Array,
    default: null,
  },
  labels: {
    type: Array,
    default: null,
  },
  bottomArrows: {
    type: Boolean,
    default: true,
  },
  checkboxes: {
    type: Boolean,
    default: false,
  },
  orderFields: {
    type: Array,
    default: [],
  },
  isOrderedColumns: {
    type: Boolean,
    default: false,
  },
});

const actualPage = ref(1);
const totalPages = computed(() =>
  props?.data?.count ? Math.ceil(props.data.count / 10) : 1
);
const selectAll = ref(false);
const selectedItems = ref([]);

const emit = defineEmits(["changePage", "selectionChanged", "toggle-order"]);

function nextPage() {
  if (props?.data.next) {
    actualPage.value++;
    emit("changePage", actualPage.value);
  }
}

function previousPage() {
  if (props?.data.previous && actualPage.value > 1) {
    actualPage.value--;
    emit("changePage", actualPage.value);
  }
}

function toggleSelectAll() {
  selectedItems.value = selectAll.value
    ? props.data.results.map((item) => item.id)
    : [];
  emit("selectionChanged", selectedItems.value);
}

function toggleSelection(itemId) {
  const index = selectedItems.value.indexOf(itemId);
  if (index > -1) {
    selectedItems.value.splice(index, 1);
  } else {
    selectedItems.value.push(itemId);
  }

  selectAll.value = selectedItems.value.length === props.data.results.length;
  emit("selectionChanged", selectedItems.value);
}

function isOrderedBy(field, direction) {
  const criteria = props.orderFields.find(
    (orderField) => orderField.replace("-", "") === field
  );
  return direction === "asc" ? criteria === field : criteria === `-${field}`;
}

watch(
  () => props.data,
  (newVal, oldVal) => {
    if (newVal) {
      selectAll.value = false;
      selectedItems.value = [];
    }
  }
);
</script>

<style scoped>
th {
  color: #1d2e48;
  background: #ecf0f5;
}

tr {
  border-top: 1px solid #ecf0f5;
  border-bottom: 1px solid #ecf0f5;
}
</style>
