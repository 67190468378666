<template>
  <div id="popup" class="overlay">
    <div class="w-11/12 md:w-2/4 popup" v-if="!showConfirmation">
      <div class="flex justify-between items-center">
        <h5 class="text-xl font-semibold">Add Member Account</h5>
        <a @click="changeShowConfirmation()" class="text-2xl font-bold" href="#"
          >&times;</a
        >
      </div>
      <hr />
      <div v-if="seatsAviable" class="mt-7">
        <form class="login-form" @submit.prevent="handleSubmit">
          <div class="form__div">
            <input
              class="form__input"
              type="email"
              placeholder=" "
              v-model="email"
              required
            />
            <label for="" class="form__label">Email</label>
          </div>
          <div class="form__div mt-2">
            <input
              class="form__input"
              type="number"
              placeholder=" "
              v-model="profiles"
              required
            />
            <label for="" class="form__label"
              >Number of seats assigned to the user</label
            >
          </div>
          <div class="">
            <div>
              <input class="ml-1" type="checkbox" v-model="underAge" />
              <span class="ml-1 italic">
                Confirm the user is 18+ or that you have consent from the parent
                according to our
                <a
                  class="underline font-bold"
                  target="_blank"
                  href="https://www.mastermindsports.com/terms-of-service"
                  >terms of service.</a
                >
              </span>
            </div>
          </div>
          <p class="italic text-red-700 mt-4 text-center" v-if="errorMessage">
            {{ errorMessage }}!
          </p>

          <div class="px-3 my-5 flex flex-row-reverse">
            <button
              type="submit"
              class="px-4 py-2 ml-12 bg-mindaro text-darkBlue"
              :disabled="!underAge"
            >
              <em
                v-if="processingRegister"
                class="fa fa-spinner fa-spin mr-2"
              ></em
              >Create
            </button>
            <a type="button" class="px-4 py-2 text-white bg-darkBlue" href="#">
              Cancel
            </a>
          </div>
        </form>
      </div>
      <div class="mt-5" v-else>
        There are not available seats, please go and buy some.
      </div>
    </div>
    <div class="popup w-11/12 md:w-1/2" v-else>
      <div class="flex justify-between">
        <h5 class="text-base md:text-xl font-semibold">
          USER HAS BEEN SUCCESSFULLY REGISTERED
        </h5>
        <a @click="changeShowConfirmation()" class="text-2xl font-bold" href="#"
          >&times;</a
        >
      </div>
      <em
        class="block mx-auto mt-5 mb-14 text-9xl fa-solid fa-circle-check check-icon"
      ></em>
      <p class="mb-5 text-center">
        The new user will receive an email with a registration link.
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, defineEmits } from "vue";
import { useStore } from "vuex";
import {
  createLocationCode,
  registrationEmail,
  numberSeats,
} from "@/services/location/location.js";

const store = useStore();

const user = computed(() => store.getters["user/getUserData"]);
const emit = defineEmits(["invitationSent"]);

const totalSeats = ref(0);
const email = ref(null);
const seatsAviable = ref(false);
const underAge = ref(false);
const profiles = ref(1);
const showConfirmation = ref(false);
const errorMessage = ref(null);
const processingRegister = ref(false);

onMounted(async () => {
  const response = await numberSeats({ location: user.value.location });

  if (response.success && response.data.number_seats > 0) {
    totalSeats.value = response.data.number_seats;
    seatsAviable.value = true;
  }
});

function changeShowConfirmation() {
  showConfirmation.value = false;
  errorMessage.value = null;
}

async function handleSubmit() {
  if (profiles.value > totalSeats.value) {
    errorMessage.value = "There are no seats available";
    return;
  }

  processingRegister.value = true;

  const data = await createLocationCode(user.value.location, "sponsor_account");

  sendRegistrationEmail(data.data.location_code);
}

async function sendRegistrationEmail(code) {
  try {
    await registrationEmail({
      email: email.value,
      code,
      isSponsorAccount: true,
      dependents: profiles.value,
    });
    showConfirmation.value = true;
    emit("invitationSent");
  } catch (error) {
    errorMessage.value = error.message;
  } finally {
    processingRegister.value = false;
  }
}
</script>

<style scoped>
.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 200ms;
  visibility: hidden;
  opacity: 0;
  z-index: 99;
}

.overlay:target {
  visibility: visible;
  opacity: 1;
}

.popup {
  margin: 75px auto;
  padding: 20px;
  background: #fff;
  border: 1px solid #666;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.5);
  position: relative;
}

.check-icon {
  height: 87px;
  width: 87px;
}

button:disabled {
  background-color: #cccccc;
}
</style>
