<template>
  <div class="h-screen">
    <router-view></router-view>
    <div class="flex justify-between p-5 mt-2">
      <h1 class="font-semibold text-xl text-white">Manage Users</h1>
      <div class="absolute md:static left-1/4 bottom-0 text-center">
        <router-link
          v-if="user.role === 'Location_admin'"
          class="left-1/4 p-2 font-semibold bg-mindaro"
          id="popup-btn"
          :to="{ name: 'Buy seats' }"
        >Pre-Purchase User Profiles
        </router-link>
        <a
          class="block md:inline p-2 font-semibold bg-mindaro mb-5 md:ml-2"
          id="popup-btn"
          href="#popup"
        >Add User -  Pre-Purchased Profile</a
        >
        <a
          class="block margin-3 md:inline md:ml-2 text-center p-2 font-semibold bg-mindaro text-darkBlue"
          href="#location"
        >Add User – User Direct Pay</a
        >
      </div>
    </div>
    <RegisterLocationUser />
    <RegisterSponsorAccountForm @invitationSent="requestInvitations" />

    <TabsWrapper class="p-5">
      <Tabs title="Registered Users">
        <LocationsTable
          :data="sponsorAccount"
          :labels="['Email', 'Name', 'Added', 'Profiles', 'Edit']"
          :edit="true"
          @changePage="loadSponsorAccounts"
      /></Tabs>
      <Tabs title="Pending Users"
        ><LocationsATable
          :data="invitations"
          :labels="['Email', 'Invited', 'Profiles', 'Delete']"
          @changePage="requestInvitations"
        >
          <template v-slot:table-content v-if="invitations">
            <tr
              v-for="invitation in invitations.results"
              :key="invitation"
              class="bg-white p-5"
            >
              <td class="text-center">
                {{ invitation.email }}
              </td>
              <td class="text-center">
                {{ formatDate(invitation.create_at) }}
              </td>
              <td class="text-center">
                {{ invitation.profiles }}
              </td>
              <td @click="deleteInv(invitation.id)" class="text-center">
                <i class="fa-solid fa-trash"></i>
              </td>
            </tr>
          </template> </LocationsATable
      ></Tabs>
    </TabsWrapper>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import LocationsTable from "@/components/location/LocationAdminTable.vue";
import LocationsATable from "@/components/location/LocationsTable.vue";
import RegisterSponsorAccountForm from "@/components/location/RegisterSponsorAccountForm.vue";
import RegisterLocationUser from "@/components/location/RegisterLocationUser.vue";
import Tabs from "@/components/general/Tabs.vue";
import TabsWrapper from "@/components/general/TabsWrapper.vue";
import {
  getSponsorAccounts,
  getInvitations,
  deleteInvitation,
} from "@/services/location/location.js";

const store = useStore();

const sponsorAccount = ref(null);
const invitations = ref(null);

const user = computed(() => store.getters["user/getUserData"]);

onMounted(async () => {
  await loadSponsorAccounts();
  await requestInvitations();
});

async function loadSponsorAccounts (page) {
  const response =  await getSponsorAccounts(page);
  sponsorAccount.value = response.data;
}

async function requestInvitations(page) {
  const invitationsResponse = await getInvitations(page);
  invitations.value = invitationsResponse.data;
}

async function deleteInv(id) {
  await deleteInvitation(id);
  invitations.value.results = invitations.value.results.filter(
    (invitation) => invitation.id !== id
  );
}

function formatDate(date) {
  const dateAdded = new Date(date);
  return `${
    dateAdded.getMonth() + 1
  }/${dateAdded.getDate()}/${dateAdded.getFullYear()}`;
}
</script>
